var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-form',{ref:"companyForm",staticClass:"company-ruleForm",attrs:{"model":_vm.form,"label-width":"100px","label-position":"top"}},[_vm._l((_vm.form.items),function(item,index){return _c('el-row',{key:item.key,attrs:{"gutter":10}},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"公司名称","prop":'items.' + index + '.name',"rules":[
          { required: true, message: '请输入公司名称', trigger: 'blur' },
        ]}},[_c('el-input',{attrs:{"disabled":_vm.submitting,"clearable":"","placeholder":"请输入"},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.name"}})],1)],1),_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"用户数上限","prop":'items.' + index + '.userCountLimit',"rules":[
          {
            required: true,
            message: '请输入用户数上限',
            trigger: ['blur', 'change'],
          },
        ]}},[_c('el-input-number',{attrs:{"prop":'items.' + index + '.userCountLimit',"disabled":_vm.submitting,"min":0,"max":1000,"label":"请输入"},model:{value:(item.userCountLimit),callback:function ($$v) {_vm.$set(item, "userCountLimit", $$v)},expression:"item.userCountLimit"}})],1)],1),_c('el-col',{attrs:{"span":4}},[_c('el-form-item',{attrs:{"label":"操作","prop":"userCountLimit"}},[_c('el-button',{attrs:{"disabled":_vm.submitting},on:{"click":function($event){return _vm.onDeleteRow(index)}}},[_vm._v("删除")])],1)],1)],1)}),_c('el-form-item',{staticStyle:{"text-align":"right","margin-top":"30px"}},[_c('el-button',{attrs:{"disabled":_vm.submitting},on:{"click":_vm.onCancel}},[_vm._v("取 消")]),_c('el-button',{attrs:{"disabled":_vm.submitting},on:{"click":_vm.addRow}},[_vm._v("新增一行")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.submitting},on:{"click":function($event){return _vm.submitForm('companyForm')}}},[_vm._v("提交创建")])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }